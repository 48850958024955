

.content {
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 80%;
    height: 10%;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}