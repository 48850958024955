.pricing-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2rem 0;
    margin-left: 15rem;
    margin-right: 15rem;
  }
  
  .pricing-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 300px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .pricing-card:hover {
    transform: scale(1.05);
  }
  
  .pricing-card h2 {
    margin-top: 0;
  }
  
  .pricing-card h3 {
    margin: 1rem 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .pricing-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-card li {
    margin-bottom: 1rem;
  }
  