

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.home,
.about,
.content,
.pricing,
.userprofile,
.sign-up,
.notfound,
.verify-email {
  background: #f0f0f1;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}

.pricing {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000000;
}

.notfound {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000000;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}

.verify-email {
  min-height: 1200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}


.ReportsTable {
  margin-bottom: 200px;
}
