.pricing-card-highlighted {
    background-color: #e6e6fa; /* Light purple */
    border-radius: 5px; /* Optional: Makes the edges round */
    padding: 20px; /* Optional: Gives some space inside the card */
    box-shadow: 0px 8px 15px rgba(0,0,0,0.1); /* Optional: Gives a little shadow to make the card stand out */
  }
  
  
  .pricing-card-highlighted {
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 300px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .pricing-card-highlighted:hover {
    transform: scale(1.05);
  }
  
  .pricing-card-highlighted h2 {
    margin-top: 0;
  }
  
  .pricing-card-highlighted h3 {
    margin: 1rem 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .pricing-card-highlighted ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-card li {
    margin-bottom: 1rem;
  }
  