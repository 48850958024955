
.RegisterPopup {
    width:100%;
}

.modalclosebtn{
    color:aliceblue;
}

.mymodal2 .modal-content{
    color:aliceblue;
    background-color: #3e2c7a;
    width:100%;
    justify-content: center;
}

.mymodal2 .modal-header .btn-close {
    color:aliceblue;
}