h1 {
font-size:50px;


}

.container{
    padding:5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth{
    width: 90%;
    max-width: 600px;
    border: 1x solid #f0f0f1;
    padding: 20px;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  
  .auth form{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 0 auto 10px auto;
  }
  
  .auth__success{
    padding: 10px 0;
    background-color: hsl(147, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth__error{
    padding: 10px 0;
    background-color: hsl(0, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth form > input, button{
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }

  h1 {
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }