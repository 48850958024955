:root{
    --primary: #f0f0f1;
}

.btnv {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btnv--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);

}

.btnv--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    margin-top: 10px;
}

.btnv--medium {
    padding: 8px 20px;
    font-size: 18px;
    color: #fff;
}

.btnv--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btnv--medium:hover,.btnv--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
    transition: 250ms;
}