.hero-container {
    background: #f0f0f1;
    height:400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}


.hero-container > h1 {
    color: #000000;
    font-size:50px;
    margin-top:-100px;
}

.hero-container > p {
    color: #000000;
    font-size:32px;
    margin-top:8px;
    font-family: 'Trebuchet MS'
}

.hero-btnvs .btnv{
    color:#000000;
    background: #f0f0f1;
    border-color: #f0f0f1;
    margin: 6px;
}

@media screen and (max-width: 960px){
    .hero-container > h1 {
        font-size:70px;
        margin-top:-150px;
    }
}

@media screen and (max-width: 768px){
    .hero-container > h1 {
        font-size:50px;
        margin-top:-100px;
    }
    .hero-container > p {
        font-size:30px;
    }
}

.btnv-mobile{
    display:block;
    text-decoration: none;

}
.btnv{
    width:100%;
}